"use client";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function NotFound() {
    const navigate = useNavigate();
    const [count, setCount] = useState(5);
    useEffect(() => {
        if (count === 0) {
            navigate("/");
            return;
        }

        const timer = setTimeout(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [count, navigate]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#073067] to-[#1E6BCF] flex flex-col items-center justify-center text-white">
            <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="text-center">
                <motion.h1 className="text-6xl font-bold mb-4" animate={{ scale: [1, 1.1, 1] }} transition={{ duration: 2, repeat: Infinity }}>
                    404
                </motion.h1>
                <motion.p className="text-2xl mb-8" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>
                    Oops! Page not found
                </motion.p>
                <motion.div
                    className="w-16 h-16 border-t-4 border-white rounded-full animate-spin mx-auto mb-8"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                />
                <motion.p className="text-lg" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 0.5 }}>
                    Redirecting to home in {count > 0 ? <p>{count}</p> : <p>Redirecting...</p>} seconds...
                </motion.p>
            </motion.div>
        </div>
    );
}
