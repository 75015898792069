import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import AOS from "aos";
import aboutusBg from "../../assets/aboutusbg.png";
import visionMissionBg from "../../assets/visionMissionbg.png";

import Section from "../../components/Section";

import layerCard from "../../assets/layerCard.png";
import CardCarousel from "../../components/CardCarousel";

import { projects, teamMembers, listClients, CEO_DETAILS } from "../../Data/data";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";

import Button from "../../components/Button";

const handleOpenNewTab = () => {
    window.open("/companyprofile");
};
export default function AboutUsCointainer() {
    const [activeItem, setActiveItem] = useState("Engineering");
    const [expanded, setExpanded] = useState(false);

    const menuItems = ["Engineering", "Procurement", "Training"];

    const navigate = useNavigate();

    const handleViewMore = () => {
        navigate("/aboutus/ceo-detail", { state: CEO_DETAILS });
    };

    const breadcrumb = [
        { label: "Home", path: "/" },
        { label: "About Us", path: "/aboutus" },
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
            offset: 150,
            easing: "ease-in-out",
        });
    }, []);

    return (

        <div className="h-full overflow-hidden">
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden max-md:hidden" position="end">
                <div className="h-full flex flex-col justify-center z-30 xl:max-w-[1560px] mx-auto">
                    <div className="space-y-3">
                        <div className="text-[#003478] text-lg md:text-xl font-normal" data-aos="fade-down">
                            About Us
                        </div>
                        <div className="text-[#003478] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            {" "}
                            We help your business grow
                        </div>
                        <div className="text-[#67B7FD] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            together.
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 -z-10 w-full h-full bg-gradient-to-r from-white to-white/40"></div>

                <img
                    className="absolute md:-right-24 md:top-0 inset-0 w-full h-full object-cover -z-50 md:hidden "
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
                <img
                    className="absolute -right-24 top-0 -z-50 object-cover md:block hidden"
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
            </Header>
            <Header bgColor="bg-white" className="h-screen md:h-[30rem] relative overflow-hidden md:px-32 px-15 md:hidden" position="center">
                <div className="h-full flex flex-col justify-center z-30 ">
                    <div className="space-y-3">
                        <div className="text-[#003478] text-lg md:text-xl font-normal" data-aos="fade-down">
                            About Us
                        </div>
                        <div className="text-[#003478] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            We help your business grow
                        </div>
                        <div className="text-[#67B7FD] text-3xl md:text-4xl font-bold" data-aos="fade-down">
                            together.
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 -z-10 w-full h-full bg-gradient-to-r from-white to-white/40"></div>

                <img
                    className="absolute md:-right-24 md:top-0 inset-0 w-full h-full object-cover -z-50 md:hidden "
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
                <img
                    className="absolute -right-24 top-0 -z-50 object-cover md:block hidden"
                    src={aboutusBg}
                    alt=""
                    style={{
                        filter: "blur(0px)",
                        maskImage: "linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%)",
                    }}
                />
            </Header>

            <main className="text-lg space-y-5  text-[#616161] ">
                <Breadcrumbs breadcrumbs={breadcrumb} className={"px-5 "} />
                <div className="xl:w-[1560px] max-w-screen-xl mx-auto md:h-[20rem] grid justify-center xl:px-14 lg:px-12 lg:py-5 font-normal text-base xl:text-xl lg:text-lg md:text-base  max-md:text-sm px-5 bg-background">
                    <div className="space-y-5">
                        <div data-aos="zoom-in">
                            PT CRI provides Reliability based asset life cycle management through the conception, design, construction, commissioning,
                            operation and decommissioning phase.
                        </div>
                        <div className="">
                            Aiming a word class support company, CRI provides Consultancy Services, Training, Software Renting, Software Development
                            as well as Man Power Support which well concern in Safety, Reliability, Availability, Maintainability and Asset Integrity
                            with goal of Maintenance & Reliability Excellence (MREX).
                        </div>
                        <div data-aos="zoom-in">These main concerns of CRI will deliver you the best of us.</div>
                        <Button onClick={handleOpenNewTab} status="primary">
                            Company Profile
                        </Button>
                    </div>
                </div>
                <div className="h-full md:h-[480px] py-10 md:py-0 relative" bgColor="bg-blue-50">
                    <div className="w-full ">
                        <img className="absolute -z-50 top-0 left-0 object-cover h-full w-full" src={visionMissionBg} alt="" />
                        <div className="absolute -z-10 top-0 right-0 w-full h-full  bg-[#093165CC]/80 "></div>
                    </div>
                    <div className="md:flex grid justify-center items-center md:space-x-10 space-y-4 md:space-y-0 h-full px-5">
                        <div
                            className="text-white  md:w-[310px] md:h-[220px] lg:w-[460px] lg:h-[250px] xl:w-[594px] xl:h-[220px] w-full  grid place-items-center backdrop-blur-3xl bg-white/5 rounded-xl border border-blue-100 p-8"
                            data-aos="flip-right"
                        >
                            <h1 className="xl:text-4xl text-2xl">Vision</h1>
                            <p className="lg:text-xl  text-base md:font-light font-light text-center">
                                To become an internationally recognized firm which provides effective solutionsand contributes to Safety & Reliability
                                engineering development
                            </p>
                        </div>
                        <div
                            className="text-white  md:w-[310px] md:h-[220px] lg:w-[460px] lg:h-[250px] xl:w-[594px] xl:h-[220px] w-full  grid place-items-center backdrop-blur-3xl bg-white/5 rounded-xl border border-blue-100 p-8"
                            data-aos="flip-left"
                        >
                            <h1 className="md:text-4xl text-2xl">Mission</h1>
                            <p className="lg:text-xl  text-base md:font-light font-light text-center">
                                Deliver the highest quality of consultancy services, products and training to bring your business to Maintenance &
                                Reliability Excellence (MREX)
                            </p>
                        </div>
                    </div>
                </div>
                <Section className={"xl:px-32 lg:px-24 md:px-16 px-5 md:py-14 py-0 lg:space-x-20 w-full bg-background"}>
                    <div className="lg:flex grid justify-center items-center xl:space-x-20 md:space-y-0 space-y-10  ">
                        <div className="">
                            <div className="relative" data-aos="flip-right">
                                <img className="h-full max-lg:hidden" src={CEO_DETAILS.imgSrc} alt={CEO_DETAILS.name} />
                                <img
                                    className="absolute bottom-0 z-10 w-full h-full object-cover pointer-events-none rounded-2xl max-lg:hidden"
                                    src={layerCard}
                                    alt=""
                                />
                                <div className="absolute z-20 bottom-5 px-5 text-white md:text-xl text-lg max-lg:hidden">
                                    <h1>{CEO_DETAILS.name}</h1>
                                    <p className="text-sm font-normal">{CEO_DETAILS.role}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-[853px] lg:space-x-10 space-y-10 font-normal lg:px-0 px-5">
                            <h1 className="text-4xl font-semibold lg:px-10 text-[#585858]" data-aos="fade-down">
                                Get to know our CEO
                            </h1>
                            <p data-aos="fade-down ">
                                {CEO_DETAILS.summary.slice(0, 1).map((line, index) => (
                                    <div key={index} data-aos="fade-down">
                                        <p className="xl:text-xl lg:text-lg md:text-base text-sm">{line}</p>
                                    </div>
                                ))}
                            </p>

                            <Button title="View More" status="primary" onClick={handleViewMore} />
                        </div>
                    </div>
                </Section>
                <Section className={" h-full md:px-10 max-md:px-5 md:py-14 py-0 bg-background"}>
                    <div className="w-full xl:max-w-[1560px] mx-auto">
                        <div className="md:flex md:space-x-2 md:space-y-0 space-y-2 xl:px-52 font-normal w-full">
                            <div className="my-auto text-4xl font-semibold w-1/3 flex xl:space-x-5 space-x-3">
                                <div data-aos="fade-down">Our</div>
                                <div className="text-[#67B7FD]" data-aos="fade-left">
                                    Excellent
                                </div>
                            </div>
                            <div className="md:flex-1 xl:text-xl lg:text-lg md:text-base text-sm" data-aos="fade-left">
                                Ever since CRI is established, our experts have contribute a lot in the development and improvement of Safety,
                                Reliability, Availability, Maintainability, Asset Integrity Management, Risk Based Management, and customized
                                software.
                            </div>
                        </div>
                        <div className="flex max-md:flex-col md:flex-wrap overflow-x-auto md:overflow-x-visible justify-start md:justify-center items-center xl:px-22 lg:py-10 xl:space-x-14 lg:space-x-5  space-x-5 max-md:hidden">
                            {teamMembers.map((member, index) => (
                                <div className="flex-shrink-0" key={index}>
                                    <div className="flex flex-col justify-center items-center">
                                        <img
                                            className="xl:w-[260px] lg:w-[200px] md:w-[130px] w-[200px] object-cover z-20"
                                            src={member.imageSrc}
                                            alt={member.name}
                                            data-aos="fade-left"
                                        />
                                        <div
                                            className="content w-full text-center xl:text-sm lg:text-[10px] md:text-[7px]"
                                            data-aos="fade-right data"
                                        >
                                            <div className="text-[#1E388B] font-bold xl:text-base lg:text-sm md:text-sm text-xs">{member.name}</div>
                                            <div className="text-[#1E388B] xl:text-base lg:text-sm md:text-sm text-xs">{member.position}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex max-md:flex-col md:flex-wrap overflow-x-auto md:overflow-x-visible justify-center md:justify-center items-center xl:px-22  md:hidden">
                            {teamMembers.map((member, index) => (
                                <div className="flex-shrink-0" key={index}>
                                    <div className="flex flex-col justify-center items-center">
                                        <img
                                            className="xl:w-[260px] lg:w-[200px] md:w-[130px] w-[200px] object-cover z-20"
                                            src={member.imageSrc}
                                            alt={member.name}
                                            data-aos="fade-left"
                                            data-aos-offset="30"
                                        />
                                        <div
                                            className="content w-full text-center xl:text-sm lg:text-[10px] md:text-[7px]"
                                            data-aos="fade-right data "
                                            data-aos-offset="30"
                                            data-aos-duration="500"
                                        >
                                            <div className="text-[#1E388B] font-bold xl:text-base lg:text-sm md:text-sm text-xs ">{member.name}</div>
                                            <div className="text-[#1E388B] xl:text-base lg:text-sm md:text-sm text-xs">{member.position}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Section>

                <section className=" py-10 px-5 md:h-full bg-gradient-to-b to-[#0D294F] from-[#0E4691]/95 w-full md:space-y-10 space-y-5 grid justify-center items-center ">
                    <div className="xl:w-[1280px] lg:w-[950px] md:w-[660px] w-full space-y-10">
                        <div className="space-y-3">
                            <h1 className="text-5xl text-white font-semibold" data-aos="fade-right">
                                Explore Our Projects
                            </h1>
                            <p className="text-white font-normal xl:text-xl lg:text-lg md:text-base text-sm" data-aos="fade-right">
                                We make an impact on the reliability industry and help the development of large companies
                            </p>
                        </div>
                        <div className="md:space-y-10 space-y-5 ">
                            {/* <div className="md:space-x-10  md:text-lg text-sm space-x-2  text-[#67B7FD]">
                                {categoryProject && (
                                    <div className="flex justify-start items-center max-w-6xl">
                                        {categoryProject.map((item) => (
                                            <div
                                                key={item.id}
                                                className={`cursor-pointer md:p-4 p-3 rounded-xl xl:text-xl lg:text-lg md:text-base text-sm ${
                                                    activeItem === item ? "text-[#67B7FD] border-[#67B7FD] border" : "text-[#C2C2C2]"
                                                }`}
                                                onClick={() => setActiveItem(item.id)}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div> */}
                            <div className="flex md:justify-start justify-center md:space-x-10 items-center md:text-lg text-sm space-x-2  text-[#67B7FD]">
                                {menuItems.map((item) => (
                                    <div
                                        key={item}
                                        className={`cursor-pointer md:p-4 p-3 rounded-xl font-medium ${
                                            activeItem === item ? "text-[#67B7FD] border-[#67B7FD] border" : "text-[#C2C2C2]"
                                        }`}
                                        onClick={() => setActiveItem(item)}
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`grid lg:grid-cols-2 md:w-full md:gap-x-7 gap-y-3 overflow-hidden ${
                                    expanded ? "md:h-[500px] h-[500px] overflow-y-scroll" : "md:h-[300px] h-[300px]"
                                } transition-all duration-300 ease-in-out`}
                            >
                                {/* {project && project[activeItem] && (
                                    <div>
                                        <div
                                            className={`grid lg:grid-cols-2 md:w-full md:gap-x-7 gap-y-3 overflow-hidden ${
                                                expanded ? "md:h-[500px] h-[500px] overflow-y-scroll" : "md:h-[300px] h-[300px]"
                                            } transition-all duration-300 ease-in-out`}
                                        >
                                            {project &&
                                                project.map((projectItem, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex md:w-full px-3 md:h-32 backdrop-blur-3xl bg-white/10 rounded-xl md:px-6 py-4 space-x-3"
                                                        data-aos="flip-right"
                                                    >
                                                     
                                                        <div className="md:w-24 md:h-24 bg-white rounded-lg overflow-hidden flex justify-center items-center md:p-1">
                                                            <img
                                                                className="w-full object-cover p-3"
                                                                src={projectItem.imgSrc} // Pastikan imgSrc tersedia di data proyek
                                                                alt={projectItem.title}
                                                            />
                                                        </div>
                                                 
                                                        <div className="flex-1 md:text-[16px] text-xs py-1 md:leading-loose md:space-y-3">
                                                            <p className="text-white font-semibold xl:text-base lg:text-xs md:text-sm">
                                                                {projectItem.title}
                                                            </p>
                                                            <p className="text-[#C2C2C2] font-light xl:text-base lg:text-xs md:text-sm">
                                                                {projectItem.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )} */}

                                {projects[activeItem] &&
                                    projects[activeItem].slice(0, expanded ? 10 : 4).map((project, index) => (
                                        <div
                                            key={index}
                                            className="flex md:w-full px-3 md:h-32 backdrop-blur-3xl bg-white/10 rounded-xl md:px-6 py-4 space-x-3"
                                        >
                                            <div className="md:w-24 w-24 h-24 bg-white rounded-lg overflow-hidden flex justify-center items-center md:p-1">
                                                <img className="w-full object-cover p-3" src={project.imgSrc} alt={project.title} />
                                            </div>
                                            <div className="flex-1 md:text-[16px] text-xs py-1 md:leading-loose">
                                                <p className="text-white font-semibold xl:text-base md:text-base">{project.title}</p>
                                                <p className="text-[#C2C2C2] font-light xl:text-base md:text-base">{project.description}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="w-full flex justify-center items-center">
                            <Button status="secondary" onClick={() => setExpanded(!expanded)}>
                                {expanded ? "View Less" : "View More"}
                            </Button>
                        </div>
                    </div>
                </section>
                <Section className="w-full xl:px-32 xl:py-10 xl:space-y-5 px-10 bg-background">
                    <div className="w-full xl:max-w-[1560px] mx-auto">
                        <div className="w-full md:flex justify-between md:space-y-0 space-y-5 text-4xl font-semibold ">
                            <div className="md:w-1/3 xl:text-4xl lg:text-3xl md:text-2xl text-3xl max-md:px-10">
                                CRI
                                <span className="text-[#67B7FD]" data-aos="fade-down">
                                    Valuable Clients
                                </span>
                                & Partners
                            </div>
                            <div className="flex-1 max-md:px-10" data-aos="fade-left ">
                                <p className="font-normal md:text-end text-start xl:text-xl lg:text-lg md:text-base text-sm">
                                    We have a wide range of experience in helping many companies
                                </p>
                            </div>
                        </div>
                        <div className="w-full max-md:px-10">
                            <CardCarousel listclient={listClients} />
                        </div>
                    </div>
                </Section>
            </main>
        </div>
        //     )}
        // </div>
    );
}
